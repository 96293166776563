﻿.contact-information {
	overflow: hidden;
	margin: 0 0 14px;

	&__title {
		float: left;
		width: 100%;

		@include breakpoint(ml) {
			width: 212px;
		}
	}

	&__description {
		float: left;
		margin: 0;

		@include breakpoint(ml) {
			width: calc(100% - 212px);
		}

		p {
			margin-bottom: 8px !important;
		}
	}

	&__link {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}
