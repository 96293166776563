﻿/*------------------------------------*\
	#Responsive nav
\*------------------------------------*/

.responsive-nav {
	position: fixed;
	z-index: 10;
	width: 320px;
	height: 100%;
	padding: 90px 0 0;
	top: 0;
	right: 0;
	transition: right 0.3s ease-in-out;
	background: $color-secondary;
	overflow-x: hidden;
	overflow-y: visible;
	box-shadow: -5px 0px 5px 0px rgba(0,0,0,0.25);

	&.-collapsed {
		right: -325px;
	}

	&:before {
		display: none;
	}

	@include breakpoint(l) {
		position: static;
		width: 100%;
		height: 80px;
		opacity: 1;
		background: none;
		overflow: visible;
		transition: none;
		box-shadow: none;
		padding: 0;

		&.-collapsed {
			left: 0;
			transition: none;
		}

		&.headroom--not-top {
			position: fixed;
		}

		&:before {
			display: block;
		}
	}
}