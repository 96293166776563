﻿.footer-blocks {
	position: relative;
	z-index: 1;
	padding: 88px 0 60px;
	border-top: 16px solid $color-secondary;
	background: $color-primary;
	color: $color-white;
	overflow: hidden;

	@include breakpoint(l) {
		padding: 28px 0 60px;
	}

	@extend .background;

	&__title {
		margin-bottom: 11px;
		font: oblique 500 20px/36px $font-text;
		letter-spacing: 0.25em;
		text-transform: uppercase;
	}

	&__list {
		padding: 0;
		margin: 0 0 21px;
		list-style: none;
	}

	&__list-item {

		&:last-child .footer-blocks__list-link {
			border-bottom: none;
		}
	}

	&__list-link {
		position: relative;
		display: block;
		padding: 7px 20px 6px 40px;
		border-bottom: 1px solid rgba($color-white, 0.2);
		font: oblique 500 16px/26px $font-text;
		letter-spacing: 0.25em;
		text-transform: uppercase;
		transition: color 0.2s ease-in-out;

		&:before {
			@include icon($icon-arrow-bold-right);
			position: absolute;
			width: 20px;
			height: 20px;
			padding-top: 6px;
			border-radius: 4px;
			top: 10px;
			left: 0;
			background: $color-secondary;
			font-size: 8px;
			color: $color-primary;
			text-indent: 6px;
		}

		&:hover {
			color: $color-secondary;
		}
	}

	&__slogan-container {
		display: flex;
		align-items: center;
	}

	&__slogan {
		font: oblique 300 28px/36px $font-text;
		letter-spacing: 0.25em;
		width: 100%;
		padding: 22px 0;
		text-align: center;
		color: $color-secondary;
		text-transform: uppercase;
	}
}
