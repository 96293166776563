﻿.nav-main {

	> ul {
		@include clearfix;
		width: 100%;
		padding: 0 0 70px;
		margin: 0;
		list-style: none;

		@include breakpoint(l) {
			display: flex;
			position: relative;
			justify-content: center;
			float: none;
			max-width: 1280px;
			padding: 0 20px;
			margin: 0 auto;
			text-align: center;
		}

		> li {
			position: relative;
			float: left;
			width: 100%;

			&:first-child + li a {
				border-top: 1px solid rgba($color-white, 0.2);
			}

			&:last-child a {
				border-bottom: 1px solid rgba($color-white, 0.2);
			}

			@include breakpoint(l) {
				width: auto;

				&:first-child + li a {
					border-top: none;
				}

				&:last-child a {
					border-bottom: none;
				}
			}

			a {
				position: relative;
				display: block;
				font: oblique 16px/1.2em $font-text;
				padding: 12px 20px 9px;
				color: $color-white;
				text-transform: uppercase;
				letter-spacing: 0.25em;

				@include breakpoint(l) {
					padding: 32px 20px 29px;
				}
			}

			&:hover {

				> a {
					@include breakpoint(l) {
						background: $color-primary;
					}
				}

				> ul {
					display: block;
				}
			}

			&.active > a span {
				text-decoration: underline;
			}

			&.home {
				position: absolute;
				width: 150px;
				height: 55px;
				top: 12px;
				left: 20px;
				background: url(../../../images/logo-brazander-small.png) no-repeat;
				background-image: url(../../../images/logo-brazander-small.png), none;
				text-indent: -9999px;
				overflow: hidden;
				transition: opacity 0.2s ease-in-out;

				@include breakpoint(l) {
					opacity: 0;
				}

				a {
					padding: 0;
					width: 100%;
					height: 100%;
				}

				.headroom--not-top & {
					opacity: 1;
				}

				&:hover a {
					background: transparent;
				}
			}

			ul {
				min-width: 100%;
				padding: 0;
				margin: 0;
				list-style: none;
				background: $color-primary;
				box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);

				@include breakpoint(l) {
					left: 50%;
					transform: translateX(-50%);
				}

				> li {
					float: left;
					position: relative;
					display: inline-block;
					clear: both;
					width: 100%;

					> a {
						white-space: nowrap;
						padding: 7px 32px;
						font-size: 14px;
						background: mix($color-secondary, $color-white, 80%);
						border-bottom: 1px solid rgba($color-white, 0.2);

						@include breakpoint(l) {
							padding: 7px 22px;
							font-size: 16px;
							background: none;
							border-bottom: none;
						}
					}

					&:hover > a,
					&.active > a {
						text-decoration: underline;
					}

					@include breakpoint(l) {

						&:first-child > a {
							margin-top: 17px;
						}

						&:last-child > a {
							margin-bottom: 30px;
						}
					}
				}

				@include breakpoint(l) {
					position: absolute;
					display: none;
					border-radius: 0 0 5px 5px;
				}
			}
		}
	}
}
