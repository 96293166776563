﻿input[type=text],
input[type=password],
input[type=email],
input[type=tel],
input[type=date],
textarea,
select {
	font: 300 16px/1.2em $font-text;
	padding: 6px 16px 7px;
	-webkit-appearance: none;
	outline: none;
	height: 40px;
	margin: 0;
	border: 1px solid rgba($color-primary, 0.2);
	color: $color-primary;
	background: $color-white;
	border-radius: 4px;
	overflow: hidden;

	&.input-validation-error {
		background: mix(red, #fff, 20%);
		border-color: mix(red, #fff, 40%);
	}

	&.required {
		border-left: 1px solid mix(red, #fff, 40%);
	}

	&.-borderless {
		border: none;
	}
}

input[type=date] {
	text-transform: uppercase;
}

input[type=file] {
	
	&.required {
		border-left: 2px solid mix(red, #fff, 40%);
	}
}

textarea {
	resize: vertical;
	overflow: auto;
	height: auto;
	min-height: 190px;

	&.-contact {
		min-height: 140px;
	}
}

label {
	font: 300 16px/1.2em $font-text;
	padding: 10px 16px 11px 0;
	margin: 0;
}

select {
	-moz-appearance: none;
	border-radius: 0;
	background: $color-white url(../../../images/dropdown.jpg) right center no-repeat;
	padding-right: 50px;

	&.-borderless {
		border: none;
	}
}

select::-ms-expand {
	display: none;
}

.form-check {
	display: none;
}

.form__control {
	@include clearfix;
	margin-bottom: 10px;

	label {

		@include breakpoint(ts) {
			float: left;
			width: 212px;

			&.small {
				width: 88px;
				padding-left: 22px;
			}
		}

		@include breakpoint(lm) {

			&.small {
				width: 138px;
				padding-left: 32px;
			}
		}
	}

	input,
	select,
	textarea {

		@include breakpoint(ts) {
			float: left;
			width: calc(100% - 212px);

			&.smaller {
				width: 74px;
			}

			&.small {
				width: calc(100% - 374px);
			}
		}

		@include breakpoint(lm) {

			&.small {
				width: calc(100% - 424px);
			}
		}
	}

	&.-right {
		text-align: right;
	}
}

.form__checkbox {
	@include clearfix;
	
	@include breakpoint(ts) {
		margin-bottom: 10px;
		padding-left: 212px;
	}
}
