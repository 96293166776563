﻿// Helvetica Neuel TStd
@font-face {
	font-family: 'Helvetica Neuel TStd';
	src: url('../../fonts/HelveticaNeueLTStd-LtCn.eot');
	src: url('../../fonts/HelveticaNeueLTStd-LtCn.eot?#iefix') format('embedded-opentype'), url('../../fonts/HelveticaNeueLTStd-LtCn.woff2') format('woff2'), url('../../fonts/HelveticaNeueLTStd-LtCn.woff') format('woff'), url('../../fonts/HelveticaNeueLTStd-LtCn.svg') format('truetype');
	font-weight: 300;
	font-style: oblique;
}

@font-face {
	font-family: 'Helvetica Neuel TStd';
	src: url('../../fonts/HelveticaNeueLTStd-Lt.eot');
	src: url('../../fonts/HelveticaNeueLTStd-Lt.eot?#iefix') format('embedded-opentype'), url('../../fonts/HelveticaNeueLTStd-Lt.woff2') format('woff2'), url('../../fonts/HelveticaNeueLTStd-Lt.woff') format('woff'), url('../../fonts/HelveticaNeueLTStd-Lt.svg') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica Neuel TStd';
	src: url('../../fonts/HelveticaNeueLTStd-Md.eot');
	src: url('../../fonts/HelveticaNeueLTStd-Md.eot?#iefix') format('embedded-opentype'), url('../../fonts/HelveticaNeueLTStd-Md.woff2') format('woff2'), url('../../fonts/HelveticaNeueLTStd-Md.woff') format('woff'), url('../../fonts/HelveticaNeueLTStd-Md.svg') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica Neuel TStd';
	src: url('../../fonts/HelveticaNeueLTStd-MdCn.eot');
	src: url('../../fonts/HelveticaNeueLTStd-MdCn.eot?#iefix') format('embedded-opentype'), url('../../fonts/HelveticaNeueLTStd-MdCn.woff2') format('woff2'), url('../../fonts/HelveticaNeueLTStd-MdCn.woff') format('woff'), url('../../fonts/HelveticaNeueLTStd-MdCn.svg') format('truetype');
	font-weight: 500;
	font-style: oblique;
}

// Thinking of betty
@font-face {
	font-family: 'Thinking of betty';
	src: url('../../fonts/ThinkingOfBetty.eot');
	src: url('../../fonts/ThinkingOfBetty.eot?#iefix') format('embedded-opentype'), url('../../fonts/ThinkingOfBetty.woff2') format('woff2'), url('../../fonts/ThinkingOfBetty.woff') format('woff'), url('../../fonts/ThinkingOfBetty.svg') format('truetype');
	font-weight: normal;
	font-style: normal;
}