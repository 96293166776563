﻿.filter {
	margin: 0 0 30px;

	&__item {
		margin: 0 36px 0 0;

		&.-not-active {
			display: inline-block;
			padding: 10px 0 11px 0;
			font: oblique 500 16px/1.2em $font-text;
			text-transform: uppercase;
			letter-spacing: 0.25em;
			overflow: hidden;
		}
	}
}
