﻿.slick-slider {
	padding: 0;
	margin: -2px 0 0;
}

.slick-dots {
	position: absolute;
	width: 100%;
	height: 10px;
	padding: 0;
	margin: 0;
	bottom: 4px;
	left: 0;
	text-align: center;

	li {
		display: inline-block;
		height: 10px;
		padding: 0 5px;

		&:hover button,
		&.slick-active button {
			background: $color-secondary;
		}
	}

	button {
		background: $color-white;
		width: 14px;
		height: 14px;
		min-height: initial;
		padding: 0;
		border-radius: 50%;
		border: none;
		text-indent: -9999px;
	}
}
