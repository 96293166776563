﻿// Gutter creation mixin
@mixin columns-gutter($size) {
	$size-half: ($size / 2 * 1px);

	&.-gutter-#{$size} {
		margin-left: ($size-half * -1);
		margin-right: ($size-half * -1);

		.column {
			border-color: transparent;
			border-style: solid;
			border-top: 0;
			border-right-width: ($size-half);
			border-bottom: 0;
			border-left-width: ($size-half);
		}
	}
}

// Columns
.columns {
	@include clearfix();

	// Columns gutter
	@include columns-gutter(2);
	@include columns-gutter(20);
	@include columns-gutter(30);
	@include columns-gutter(32);
	@include columns-gutter(40);
}

@mixin responsive-columns-styles($amount) {
	width: (100% / $amount);

	&:nth-child(n + 1) {
		clear: none;
	}

	&:nth-child(#{$amount}n + 1) {
		clear: left;
	}
}

// Responsive columns creation mixin
@mixin responsive-columns($amount) {
	@include responsive-columns-styles($amount);

	&-m {

		@include breakpoint(m) {
			@include responsive-columns-styles($amount);
		}
	}

	&-ml {

		@include breakpoint(ml) {
			@include responsive-columns-styles($amount);
		}
	}

	&-ts {

		@include breakpoint(ts) {
			@include responsive-columns-styles($amount);
		}
	}

	&-t {

		@include breakpoint(t) {
			@include responsive-columns-styles($amount);
		}
	}

	&-l {

		@include breakpoint(l) {
			@include responsive-columns-styles($amount);
		}
	}

	&-lm {

		@include breakpoint(lm) {
			@include responsive-columns-styles($amount);
		}
	}

	&-ll {

		@include breakpoint(ll) {
			@include responsive-columns-styles($amount);
		}
	}

	&-ds {

		@include breakpoint(ds) {
			@include responsive-columns-styles($amount);
		}
	}

	&-d {

		@include breakpoint(d) {
			@include responsive-columns-styles($amount);
		}
	}

	&-dl {

		@include breakpoint(dl) {
			@include responsive-columns-styles($amount);
		}
	}
}


// Column
.column {
	float: left;
	clear: left;
	width: 100%;

	&.-relative {
		position: relative;
	}

	// Dual
	&.-dual {
		@include responsive-columns(2);
	}

	// Triple
	&.-triple {
		@include responsive-columns(3);
	}

	// Quad
	&.-quad {
		@include responsive-columns(4);
	}

	// Quin
	&.-quin {
		@include responsive-columns(5);
	}

	// Sext
	&.-sext {
		@include responsive-columns(6);
	}

	// Sept
	&.-sept {
		@include responsive-columns(7);
	}
}