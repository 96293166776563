﻿.language {
	position: fixed;
	z-index: 100;
	width: (100% / 3);
	padding: 0;
	margin: 0;
	bottom: 0;
	right: (100% / 3);
	list-style: none;

	@include breakpoint(l) {
		position: absolute;
		width: auto;
		top: 0;
		right: 20px;
		bottom: auto;
	}

	li {
		float: left;
		width: 50%;

		@include breakpoint(l) {
			width: auto;

			&:first-child a {
				border-bottom-left-radius: 5px;
			}

			&:last-child a {
				border-bottom-right-radius: 5px;
			}
		}

		&.active a {
			background: $color-secondary;
			color: $color-white;
		}
	}

	a {
		display: block;
		font: oblique 18px/50px $font-text;
		width: 100%;
		height: 50px;
		background: $color-primary;
		color: $color-white;
		text-transform: uppercase;
		text-align: center;

		@include breakpoint(l) {
			background: rgba($color-primary, 0.6);
			color: rgba($color-white, 0.5);
			line-height: 40px;
			width: 40px;
			height: 40px;
		}
	}
}
