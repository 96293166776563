﻿/*------------------------------------*\
	#Toggler
\*------------------------------------*/

.toggler {
	z-index: 101;
	position: fixed;
	width: 320px;
	height: 50px;
	right: 0;
	bottom: 0;
	text-indent: -9999px;
	color: $color-white;
	cursor: pointer;
	transition: width 0.3s ease-in-out;
	background: $color-primary;

	&:after {
		position: absolute;
		font-size: 20px;
		text-indent: 0;
		top: 6px;
		left: 50%;
		transform: translateX(-50%);
		@include icon($icon-close);
	}

	&:before {
		position: absolute;
		content: attr(data-text);
		width: 100%;
		bottom: -1px;
		left: 0;
		font: 15px/26px $font-text;
		text-indent: 0;
		text-align: center;

		@include breakpoint(l) {
			display: none;
		}
	}

	span {
		position: absolute;
		display: block;
		width: 100%;
		bottom: -1px;
		left: 0;
		font: 15px/26px $font-text;
		text-indent: 0;
		text-align: center;
	}

	@include breakpoint(l) {
		display: none;
	}

	&.-closed {
		width: (100% / 3);

		&:after {
			@include icon($icon-menu);
		}
	}
}
