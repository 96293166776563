﻿@mixin button-hover() {
	background-color: $color-primary;

	&:before {
		width: 100%;
	}
}

@mixin button-white-hover() {
	color: $color-primary;

	&:before {
		width: 100%;
	}
}

.button {
	position: relative;
	z-index: 1;
	display: inline-block;
	font: oblique 500 16px/1.2em $font-text;
	padding: 11px 26px 10px 22px;
	transition: background 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;
	-webkit-appearance: none;
	cursor: pointer;
	text-decoration: none;
	background-color: $color-primary;
	color: $color-white;
	border: none;
	border-radius: 4px;
	outline: none;
	overflow: hidden;
	text-transform: uppercase;
	letter-spacing: 0.25em;

	&:before {
		position: absolute;
		z-index: -1;
		content: '';
		width: 5px;
		height: 100%;
		background: $color-secondary;
		top: 0;
		left: 0;
		transition: width 0.3s ease-in-out;
	}

	&:hover {
		@include button-hover();
	}

	&.-full-width {
		width: 100%;
		text-align: center;
	}

	&.-white {
		padding: 10px 26px 9px 22px;
		border: 1px solid $color-white;

		&:before {
			width: 4px;
			background: $color-white;
		}

		&:hover {
			@include button-white-hover();
		}
	}

	&.-black-transparent {
		@extend .-white;
		background: rgba($color-black, 0.6);
	}
}

.buttons {
	margin: -5px -5px;

	.button {
		margin: 5px;
	}
}