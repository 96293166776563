﻿.product-information {
	position: absolute;
	z-index: 2;
	width: 100%;
	padding: 42px 0 18px;
	left: 0;
	background: mix($color-secondary, $color-white, 5%);
	transform: scaleY(0);
	transform-origin: top;
	//transition: transform 0.3s ease-in-out;
	box-shadow: 0px 3px 6px 0px rgba($color-primary, 0.2);

	p,
	ul,
	ol {
		margin-bottom: 16px;
	}

	&__list {
		margin: 5px 0 0;
	}

	&__list-title {
		width: 144px;
		font-weight: 500;

		&:first-child {
			border-top: none;

			+ .product-information__list-description {
				border-top: none;
			}
		}
	}

	&__list-description {
		width: calc(100% - 144px);
		margin: 0;
	}

	&__list-title,
	&__list-description {
		float: left;
		border-top: 1px solid rgba($color-primary, 0.2);
		padding: 6px 0 10px
	}
}
