﻿.call-to-action {
	color: $color-white;

	&__content-container {
		position: relative;
		overflow: hidden;
		z-index: 0;
		display: flex;
		align-items: center;
		min-height: 250px;
		border-bottom: 8px solid $color-secondary;

		@extend .background;

		@include breakpoint(t) {
			min-height: 400px;
			border-right: 8px solid $color-secondary;
			border-bottom: none;
		}
	}

	&__content {
		width: 100%;
		padding: 19px 40px 20px;
		text-align: center;
	}

	&__title {
		font: oblique 500 28px/36px $font-text;
		text-transform: uppercase;
		letter-spacing: 0.25em;
		margin-bottom: 32px;
	}

	&__image {
		min-height: 250px;
		background-size: cover;
		background-position: center;
		border-top: 8px solid $color-secondary;

		@include breakpoint(t) {
			min-height: 400px;
			border-top: none;
			border-left: 8px solid $color-secondary;
		}
	}
}
