﻿.video-block {
	position: relative;
	padding: 92px 0 100px;

	&__video-wrapper {
		max-width: 960px;
		margin: 43px auto 0;
		border: 10px solid #fff;
	}

	&__video {
		@include responsive-container(960, 560);

		iframe {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}
}
