.image {

	&__container {
		@include responsive-container(600, 400);
		display: block;
		margin: 6px 0 26px;
		background: rgba($color-secondary, 0.05);
		border-radius: 4px;

		&.-wide {
			@include responsive-container(1240, 400);
			margin: 23px 0 26px;
		}
	}
}

.images {
	margin-top: -9px;
}