﻿.route-form {
	max-width: 816px;
	width: 100%;
	padding: 20px 0 10px;
	margin: 0 auto;


	form {
		margin: 0 -9px;

		@include breakpoint(t) {
			display: flex;
		}

		input,
		select,
		.button {
			width: 100%;
			margin: 0 0 10px;

			@include breakpoint(t) {
				width: auto;
				margin: 0 9px 10px;
			}
		}

		input {
			flex-grow: 1;
		}

		select {
			flex: 1 1 auto;

			@include breakpoint(t) {
				max-width: 262px;
			}
		}

		button {
			flex: 0 0 auto
		}
	}

	&__wrapper {
		position: relative;
		z-index: 0;
		background: $color-primary;
		border-top: solid 16px $color-secondary;
		@extend .background;
	}
}
