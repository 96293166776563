﻿.logo {
	position: absolute;
	z-index: 1;
	float: left;
	display: block;
	width: 150px;
	height: 106px;
	margin: 0;
	top: 20px;
	left: 50%;
	transform: translateX(-50%);
	text-indent: -9999px;
	background: url(../../../images/logo-brazander.png) no-repeat;
	background-image: url(../../../images/logo-brazander.png), none;
	background-size: 100% auto;

	.frontpage & {
		@include breakpoint(l) {
			width: 250px;
			height: 177px;
			top: 68px;
			background: url(../../../images/logo-brazander-frontpage.png) no-repeat;
			background-image: url(../../../images/logo-brazander-frontpage.png), none;
		}
	}
}
