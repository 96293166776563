@mixin clearfix() {
	/*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
	&:before,
	&:after {
		content: " "; // 1
		display: table; // 2
	}

	&:after {
		clear: both;
	}
	// For IE 6/7 only: include this rule to trigger hasLayout and contain floats.
	*zoom: 1;
}