﻿header {
	position: relative;
	z-index: 3;
	height: 310px;
	background-size: cover;
	background-position: center;

	@include breakpoint(ml) {
		z-index: 3;
	}

	.frontpage & {
		height: 390px;

		@include breakpoint(ml) {
			height: 490px;
		}

		@include breakpoint(l) {
			height: 690px;
		}
	}

	&:before {
		position: absolute;
		z-index: -1;
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba($color-primary, 0.8);
	}

	&:after {
		position: absolute;
		z-index: -2;
		content: '';
		width: 100%;
		height: 250px;
		top: 0;
		left: 0;
		background: linear-gradient(to bottom, rgba(96,7,36,1) 0%,rgba(96,7,36,0) 100%);
	}
}
