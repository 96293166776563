﻿.to-top {
	position: absolute;
	z-index: 1;
	display: block;
	width: 60px;
	height: 60px;
	padding: 20px 0 0 0;
	top: -88px;
	right: 20px;
	background: $color-secondary;
	color: $color-primary;
	text-align: center;
	border-radius: 0 0 4px 4px;
	transition: color 0.3s ease-in-out;

	@include breakpoint(l) {
		top: -28px;
	}

	&:before {
		@include icon($icon-arrow-bold-up);
	}

	&:after {
		position: absolute;
		z-index: -1;
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: $color-primary;
		transform: scaleY(0);
		transform-origin: bottom;
		transition: transform 0.3s ease-in-out;
		font-size: 11px;
	}

	&:hover {
		color: $color-secondary;

		&:after {
			transform: scaleY(1);
		}
	}
}
