﻿.container {
	@include clearfix;
	width: 100%;
	max-width: 1280px;
	padding: 0 20px;
	margin: 0 auto;

	&.-relative {
		position: relative;
		z-index: 2;
	}

	&.-title {
		position: relative;
		z-index: 1;
		padding-top: 186px;
	}
}
