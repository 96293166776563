﻿.slide {
	position: relative;
	z-index: 1;
	background-size: cover;
	background-position: center;
	height: 390px;

	@include breakpoint(ml) {
		height: 490px;
	}

	@include breakpoint(l) {
		height: 690px;
	}

	&:before {
		position: absolute;
		z-index: -2;
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba($color-primary, 0.8);
	}

	&:after {
		position: absolute;
		z-index: -1;
		content: '';
		width: 100%;
		height: 250px;
		top: 0;
		left: 0;
		background: linear-gradient(to bottom, rgba(96,7,36,1) 0%,rgba(96,7,36,0) 100%);
	}

	&__content {
		position: absolute;
		width: 100%;
		padding-top: 140px;
		top: 0;
		left: 0;

		@include breakpoint(ml) {
			padding-top: 200px;
		}

		@include breakpoint(l) {
			padding-top: 445px;
		}
	}

	&__title {
		font: 20px/30px $font-text;
		letter-spacing: 0.25em;
		text-transform: uppercase;
		color: $color-white;

		@include breakpoint(l) {
			font-size: 28px;
			line-height: 36px;
		}
	}

	&__container {
		text-align: center;
	}
}

.slideshow {

	&.slick-slider {
		margin: 0;
	}

	.slick-dots {
		bottom: 26px;
	}

	&.-frontpage {
		
		.slick-dots {
			@include breakpoint(ml) {
				height: 76px;
			}
		}
	}
}
