﻿.footer-contact {
	background: mix($color-secondary, $color-white, 5%);

	&__content-container {
		display: flex;
		align-items: center;

		@include breakpoint(l) {
			min-height: 322px;
		}
	}

	&__content {
		padding: 28px 0;
	}

	&__title {
		font: oblique 24px/30px $font-text;
		letter-spacing: 0.25em;
		text-transform: uppercase;
		margin-bottom: 22px;

		@include breakpoint(l) {
			font-size: 28px;
			line-height: 36px;
		}
	}

	&__image {
		display: none;
		min-height: 222px;
		background: url(../../../images/logo-brazander-footer.png) no-repeat center;
		background-image: url(../../../images/logo-brazander-footer.svg), none;
		background-size: 280px, 76px;

		@include breakpoint(ts) {
			display: block;
		}

		@include breakpoint(l) {
			min-height: 322px;
		}

		@include breakpoint(l) {
			background-size: 377px, 102px;
		}
	}
}
