﻿.background {

	&:before,
	&:after {
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	&:before {
		z-index: -2;
		background: url(../../../images/pattern-large.png);
		width: 100%;
		height: 100%;
	}

	&:after {
		z-index: -1;
		background: linear-gradient(to right, rgba(101,28,50,1) 0%,rgba(101,28,50,1) 21%,rgba(101,28,50,0) 100%);
	}
}

.background-orange {
	@extend .background;

	&:before {
		background: url(../../../images/pattern-orange-large.png);
	}

	&:after {
		display: none;
	}
}