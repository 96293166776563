﻿.checkbox {
	display: inline-block;

	input {
		display: none;

		&:checked + * + label {

			&:after {
				opacity: 1;
			}
		}
	}

	label {
		position: relative;
		display: inline-block;
		width: auto !important;
		padding: 11px 0 7px 36px;
		margin: 0 32px 0 0;
		cursor: pointer;

		&:before {
			position: absolute;
			content: '';
			width: 20px;
			height: 20px;
			border: 1px solid rgba($color-primary, 0.2);
			top: 11px;
			left: 0;
			text-align: center;
			color: transparent;
			background: $color-white;
			border-radius: 4px;
		}

		&:after {
			position: absolute;
			content: '';
			width: 14px;
			height: 14px;
			top: 14px;
			left: 3px;
			background: $color-primary;
			opacity: 0;
			transition: opacity 0.2s ease-in-out;
			border-radius: 4px;
		}

		&:hover:after {
			opacity: 0.2;
		}
	}
}
