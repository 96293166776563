﻿.products {

	&:hover .product:after {
		opacity: 1;
	}
}

.product {
	position: relative;
	cursor: pointer;

	&__title {
		font: oblique 500 16px/1.2em $font-text;
	}

	&__sub-title {
		font: oblique 500 14px/1.2em $font-text;
		color: $color-secondary;
	}

	&__title,
	&__sub-title {
		display: block;
		letter-spacing: 0.25em;
		text-transform: uppercase;
		margin: 0;
		text-align: center;
	}

	&__content {
		padding: 10px 0 30px;
	}

	&:after {
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba($color-white, 0.8);
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
	}

	&:before {
		position: absolute;
		z-index: 4;
		content: '';
		width: 0;
		height: 0;
		margin-left: -20px;
		border-style: solid;
		border-width: 0 20px 20px 20px;
		border-color: transparent transparent mix($color-secondary, $color-white, 5%);
		bottom: 0;
		left: 50%;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
	}

	&__wrapper.active {

		.product {
			&:after {
				opacity: 0 !important;
			}

			&:before {
				opacity: 1;
			}
		}
	}

	&__wrapper.active {
		.product-information {
			transform: scaleY(1);
		}
	}
}