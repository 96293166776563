﻿.pcb {
	position: relative;
	text-align: center;

	@include breakpoint(l) {
		padding: 0 25%;
	}

	&__image {

		@include breakpoint(ml) {
			float: left;
			width: 50%;
		}

		@include breakpoint(l) {
			position: absolute;
			width: 25%;
			top: 50%;
			transform: translateY(-50%);
		}

		&.-right {
			@include breakpoint(ml) {
				padding-left: 16px;
				right: 0;
			}
		}

		&.-left {
			@include breakpoint(ml) {
				padding-right: 16px;
				left: 0;
			}
		}
	}

	&__image-text {
		display: block;
		font: 600 16px/36px $font-text;
		letter-spacing: 0.25em;
		text-transform: uppercase;
		text-align: center;
	}
}
