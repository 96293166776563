﻿.content-block {
	@include clearfix();
	position: relative;
	padding: 83px 0 44px;

	&:nth-of-type(even) {
		background: mix($color-secondary, $color-white, 5%);
	}

	.content-block__content {

		p,
		ol,
		ul {
			margin: 0 0 24px;

			&:last-child {
				margin-bottom: 20px;
			}
		}
		/*ul {
			list-style: none;
			padding: 0;
			margin: -7px 0 27px;

			li {
				position: relative;
				padding: 6px 20px 3px 37px;
				//border-top: 1px solid $color-primary-lighter;
				&:before {
					//@include icon($icon-arrow-right);
					position: absolute;
					font-size: 9px;
					top: 19px;
					left: 15px;
				}

				&:last-child {
					//	border-bottom: 1px solid $color-primary-lighter;
				}
			}

			&:last-child {
				margin-bottom: 24px;
			}
		}*/
		a {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	.buttons {
		margin-top: 20px;
		margin-bottom: 15px;
	}
}
