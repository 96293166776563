﻿.legal {
	position: relative;
	padding: 13px 0;
	margin: 0 auto;
	overflow: hidden;
	font: oblique 300 12px/16px $font-text;
	text-transform: uppercase;
	letter-spacing: 0.25em;

	ul {
		list-style: none;
		overflow: hidden;
		padding: 0;
		margin: 0;
	}

	li {
		float: left;
		margin-right: 8px;
		color: $color-primary;
		padding-top: 4px;
		padding-bottom: 4px;
	}

	a {

		&:hover {
			text-decoration: underline;
		}
	}
}

li.-panorama {

	a {
		color: $color-primary;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	@include breakpoint(ts) {
		float: right;
	}
}