﻿.shortcuts {
	margin-top: 1px;
	margin-bottom: 40px;
}

.shortcut {
	@include responsive-container(450, 350);
	background-size: cover;
	background-position: center;
	margin: 16px 0;
	border-radius: 4px;

	&__button-container {
		position: absolute;
		width: 100%;
		padding: 0 16px;
		bottom: 34px;
		text-align: center;
	}
}
