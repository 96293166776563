﻿$icon-arrow-bold-down: "\ea01";
$icon-arrow-bold-left: "\ea02";
$icon-arrow-bold-right: "\ea03";
$icon-arrow-bold-up: "\ea04";
$icon-close: "\ea05";
$icon-menu: "\ea06";
$icon-phone: "\ea07";

@at-root {
    @font-face {
        font-family: icon;
        
        src: url("../../fonts/icon.woff2") format("woff2"), url("../../fonts/icon.woff") format("woff"); 
        font-style: normal;
        font-weight: normal;
    }
}

@mixin icon($content: none) {
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    font-family: icon;
    line-height: 1;
    text-transform: none;
    speak: none;
    content: $content;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon:before {
    @include icon;
}

.icon-arrow-bold-down:before { content: $icon-arrow-bold-down; }
.icon-arrow-bold-left:before { content: $icon-arrow-bold-left; }
.icon-arrow-bold-right:before { content: $icon-arrow-bold-right; }
.icon-arrow-bold-up:before { content: $icon-arrow-bold-up; }
.icon-close:before { content: $icon-close; }
.icon-menu:before { content: $icon-menu; }
.icon-phone:before { content: $icon-phone; }
