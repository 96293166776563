﻿.call {
	position: fixed;
	height: 50px;
	width: (100% / 3);
	bottom: 0;
	left: 0;
	font: 15px/26px $font-text;
	letter-spacing: 0.25em;
	color: $color-white;
	background: $color-primary;
	text-indent: -99999px;
	overflow: hidden;

	@include breakpoint(l) {
		position: absolute;
		width: auto;
		height: auto;
		top: 8px;
		bottom: auto;
		left: 20px;
		background: none;
		text-indent: 0;
		font-style: oblique;
		font-weight: 100;
		font-size: 16px;
		line-height: 36px;
		text-transform: uppercase;
	}

	&:before {
		position: absolute;
		content: attr(data-text);
		width: 100%;
		bottom: -1px;
		left: 0;
		font: 15px/26px $font-text;
		text-indent: 0;
		text-align: center;

		@include breakpoint(l) {
			display: none;
		}
	}

	&:after {
		@include icon($icon-phone);
		position: absolute;
		font-size: 20px;
		text-indent: 0;
		top: 6px;
		left: 50%;
		transform: translateX(-50%);

		@include breakpoint(l) {
			display: none;
		}
	}

	a {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		font-weight: 500;

		@include breakpoint(l) {
			position: static;
			width: auto;
			height: auto;
			top: auto;
			left: auto;
		}

		&:hover {
			text-decoration: underline !important;
		}
	}
}
