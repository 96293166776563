﻿.overview {
	margin-top: -5px;
	margin-bottom: 2px;

	&:first-child {
		margin-top: -15px;
	}

	&.-employee {
		margin-top: 27px;

		&:first-child {
			margin-top: -3px;
		}
	}
}

.overview-item {
	overflow: hidden;
	margin-bottom: 32px;
	border-radius: 4px;

	&__title {
		font: oblique 500 16px/20px $font-text;
		letter-spacing: 0.25em;
		text-transform: uppercase;
		margin: 0 0 12px;
	}

	&__sub-title {
		display: block;
		margin: -12px 0 25px;
	}

	&__placeholder {
		@include responsive-container(600, 400);
		background: rgba($color-secondary, 0.1);
	}

	&__content {
		position: relative;
		z-index: 1;
		border-top: 16px solid $color-secondary;
		background: $color-primary;
		color: $color-white;
		padding: 34px 40px 7px;
		overflow: hidden;

		@extend .background;

		p {
			margin: 0 0 34px;
		}

		.button {
			margin-bottom: 27px;
		}

		&.-with-title {
			padding: 18px 40px 7px;
		}
	}

	&__list {
		@include definition-list(180, l);
		margin-bottom: 27px;
	}

	&__list-description {
		p {
			margin: 0;
		}
	}

	&__link:hover {
		text-decoration: underline !important;
	}

	.Terratype {
		@include responsive-container(600, 400);

		> div > div {
			position: absolute !important;
			width: 100% !important;
			height: 100% !important;
		}
	}
}
