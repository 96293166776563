$breakpoints: ( 
	m: 380px,
	ml: 425px,
	ts: 640px,
	t: 768px,
	l: 1024px,
	lm: 1280px,
	ll: 1440px,
	ds: 1680px,
	d: 1920px,
	dl: 2560px
);

@mixin breakpoint($bp, $rule: min-width) {
	@media only screen and (#{$rule}: map-get($breakpoints, $bp)) {
		@content;
	}
}